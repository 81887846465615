<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 sm:m-0 m-4">
      <vx-card class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <div class="vx-row no-gutter">
            <!-- <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="register" class="mx-auto" />
            </div> -->
            <div class="vx-col w-full mx-auto self-center rounded-md">
              <div v-if="fbAuth" class="p-8">
                <div class="vx-card__title text-center">
                  <h4 class="mb-4">{{ verificationSent ? 'Email verification sent!' : ' Verify your email address' }}</h4>
                  <div v-if="verificationSent">
                    <p
                      >An email verification has been sent to <strong>{{ userEmail }}</strong
                      >. If you have not received the verification email, please check your "Spam" or "Bulk Email" folder.</p
                    >
                    <!-- <p class="mt-2"
                      >You can also click the resend button below to have another email sent to you or logout and return to the login
                      page.</p
                    > -->
                  </div>

                  <div v-else
                    ><p
                      >Please verify your email address before using Recruit a Guide. Send an email verification to
                      <strong>{{ userEmail }}</strong> or logout and return to the login page.</p
                    >
                  </div>
                </div>
                <div v-if="!hideButtons" class="clearfix">
                  <!-- <vs-button type="border" class="mt-6" @click="logout">Logout</vs-button> -->
                  <vs-button class="mt-6 w-full" @click="sendVerification">{{
                    verificationSent ? 'Resend verification' : 'Send verification'
                  }}</vs-button>
                  <p class="text-sm mt-3 text-center">
                    <span class="text-rag-green-dark cursor-pointer hover:underline" @click="logout">Back to login</span></p
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
export default {
  data() {
    return {
      hideButtons: false,
      verificationSent: this.$route.params.emailSent,
      fbAuth: getAuth(),
    };
  },
  computed: {
    userEmail() {
      if (this.fbAuth.currentUser) return this.fbAuth.currentUser.email;
      return 'your email address';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    async sendVerification() {
      // const auth = getAuth();

      try {
        this.$vs.loading({ type: 'corners' });
        // await sendEmailVerification(this.fbAuth.currentUser);
        await this.$store.dispatch('auth/generateActionLink', { email: this.fbAuth.currentUser.email, linkType: 'verify-email' });
        this.$vs.notify({
          title: 'Email sent!',
          text: 'A verification link has been sent to your email address.',
          color: 'success',
          time: 8000,
        });
        this.$vs.loading.close();
      } catch (e) {
        if (e.message === 'Firebase: Error (auth/too-many-requests).' || e.message === 'too-many-requests') {
          this.$vs.notify({
            title: 'Too fast!',
            text: 'Please wait a few moments before trying again.',
            color: 'warning',
            time: 8000,
          });
        }
        this.$vs.loading.close();
        return;
      }
      this.verificationSent = true;
    },
  },
};
</script>
